import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  TextField,
  Alert,
  Slide,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Typography,
  Button,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

import { LoadingButton } from "@mui/lab";

import Iconify from "./Iconify";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import toast from "react-hot-toast";
import TauriInitializer from "./TauriInitializer";
import { useStationAuth } from "../contexts/StationAuthContext";
import { useForgetPasswordMutation } from "../generated/graphql";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LoginForm() {
  const [
    { data: forgetPasswordData, error: forgetPasswordError },
    forgetPassword,
  ] = useForgetPasswordMutation();

  const { login, showLoginForm, setShowLoginForm, isTauriApp } =
    useStationAuth();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [showResetPassword, setShowResetPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickWebLogin = async () => {
    try {
      setLoading(true);
      const loginResponse = await login({
        email,
        password,
      });

      if (loginResponse == true) {
        toast.success("Logged in!");
      } else {
        toast.error("Failed to login");
        throw new Error();
      }
      setLoading(false);
      setShowLoginForm(false);
    } catch (error) {
      console.error(error);
      setError(error?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClickResetPassword = async () => {
    try {
      setLoading(true);
      const forgotPasswordResponse = await forgetPassword({ email });
      if (forgotPasswordResponse.data?.forgetPassword?.success) {
        toast.success("Password reset email sent!");
      } else {
        toast.error("Failed to reset password");
        throw new Error();
      }
      setLoading(false);
      setShowResetPassword(false);
    } catch (error) {
      console.error(error);
      setError(error?.message);
    } finally {
      setLoading(false);
    }
  };

  if (isTauriApp) {
    return <TauriInitializer />;
  }

  return (
    <>
      <Dialog
        open={showLoginForm}
        onClose={() => {
          setShowLoginForm(false);
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          Login
          <IconButton
            aria-label="close"
            onClick={() => {
              setShowLoginForm(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ep:close-bold" />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
            minWidth: "350px",
            maxWidth: "500px",
            backgroundColor: "#3d608c",
            mt: 2,
          }}
        >
          {!isTauriApp && (
            <>
              <TextField
                fullWidth
                label="Email"
                name="Email"
                autoComplete="Email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  mt: 2,
                  "& .MuiOutlinedInput-root": {
                    // "& fieldset": {
                    //   borderColor: "white", // This will change the border color
                    // },
                    "&.Mui-focused fieldset": {
                      borderColor: "white",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "white", // This will change the label color
                    "&.Mui-focused": {
                      color: "white",
                    },
                  },
                  "& .MuiFilledInput-input": {
                    backgroundColor: "lightgray", // change this to your desired color
                  },
                }}
              />

              <FormControl
                variant="outlined"
                fullWidth
                sx={{
                  mt: 2,
                  "& .MuiOutlinedInput-root": {
                    // "& fieldset": {
                    //   borderColor: "white", // This will change the border color
                    // },
                    "&.Mui-focused fieldset": {
                      borderColor: "white",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "white", // This will change the label color
                    "&.Mui-focused": {
                      color: "white",
                    },
                  },
                  "& .MuiFilledInput-input": {
                    backgroundColor: "lightgray", // change this to your desired color
                  },
                }}
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  label="Password *"
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </>
          )}

          {error !== "" && (
            <Alert sx={{ mt: 4 }} severity="error">
              {error}
            </Alert>
          )}

          <LoadingButton
            id={"login-button"}
            fullWidth
            variant="contained"
            size="large"
            type="submit"
            loading={loading}
            disabled={email === "" || password === ""}
            sx={{
              mt: 4,
              textTransform: "initial",
              fontWeight: "bold",
            }}
            onClick={() => handleClickWebLogin()}
          >
            SIGN IN
          </LoadingButton>

          {!isTauriApp && (
            <Button
              sx={{
                mt: 2,
                textTransform: "initial",
                color: "white",
                fontWeight: "bold",
              }}
              onClick={() => {
                setShowResetPassword(true);
                setShowLoginForm(false);
              }}
            >
              <Typography sx={{ color: "white" }}>Forgot Password?</Typography>
            </Button>
          )}
        </DialogContent>
      </Dialog>

      {/* Forgot Password */}
      <Dialog
        open={showResetPassword}
        onClose={() => {
          setShowResetPassword(false);
        }}
      >
        <DialogTitle>
          Reset Password
          <IconButton
            aria-label="close"
            onClick={() => {
              setShowResetPassword(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ep:close-bold" />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
            minWidth: "350px",
            maxWidth: "350px",
            backgroundColor: "#3d608c",
            mt: 2,
          }}
        >
          {!isTauriApp && (
            <>
              <Typography variant="body2" sx={{ color: "white", mb: 2 }}>
                We'll send you a link to reset your password. Please enter your
                email address below.
              </Typography>

              <TextField
                fullWidth
                label="Email"
                name="Email"
                autoComplete="Email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  mt: 2,
                  "& .MuiOutlinedInput-root": {
                    // "& fieldset": {
                    //   borderColor: "white", // This will change the border color
                    // },
                    "&.Mui-focused fieldset": {
                      borderColor: "white",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "white", // This will change the label color
                    "&.Mui-focused": {
                      color: "white",
                    },
                  },
                  "& .MuiFilledInput-input": {
                    backgroundColor: "lightgray", // change this to your desired color
                  },
                }}
              />
            </>
          )}

          {error !== "" && (
            <Alert sx={{ mt: 4 }} severity="error">
              {error}
            </Alert>
          )}

          <LoadingButton
            id={"login-button"}
            fullWidth
            variant="contained"
            size="large"
            type="submit"
            loading={loading}
            disabled={email === ""}
            sx={{
              mt: 4,
              textTransform: "initial",
              fontWeight: "bold",
            }}
            onClick={() => handleClickResetPassword()}
          >
            Reset Password
          </LoadingButton>

          <Button
            sx={{
              mt: 2,
              textTransform: "initial",
              color: "white",
              fontWeight: "bold",
            }}
            onClick={() => {
              setShowResetPassword(false);
              setShowLoginForm(true);
            }}
          >
            <Typography sx={{ color: "white" }}>Back</Typography>
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
