import { useEffect, useState } from "react";
import { Button, TextField, Box } from "@mui/material";
import { Backdrop } from "@mui/material";
import { BaseDirectory, createDir, exists } from "@tauri-apps/api/fs";
import { appDataDir } from "@tauri-apps/api/path";
import toast from "react-hot-toast";
import { useStationAuth } from "../contexts/StationAuthContext";
import {
  useGetStationDataQuery,
  useInitializeStationMutation,
  useRefreshStationCertificateMutation,
} from "../generated/graphql";
import { fs, invoke } from "@tauri-apps/api";
import { getVersion } from "@tauri-apps/api/app";

type Props = {};

export default function TauriInitializer({}: Props) {
  const { showLoginForm, setShowLoginForm, isTauriApp } = useStationAuth();

  const [showSecret, setShowSecret] = useState(false);
  const [secret, setSecret] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);

  const [{ data: initializeStationData }, initializeStation] =
    useInitializeStationMutation();

  const [{ data: stationData, error: stationDataError }] =
    useGetStationDataQuery({
      variables: {
        secret: secret,
      },
    });
  const _stationData = stationData?.getStationData;

  const [{ data: refreshStationCertificateData }, refreshStationCertificate] =
    useRefreshStationCertificateMutation();

  useEffect(() => {
    if (refreshStationCertificateData) {
      console.info(
        "Refreshed station certificate",
        refreshStationCertificateData
      );
    }
  }, [refreshStationCertificateData]);

  useEffect(() => {
    const initializeDir = async () => {
      await createDir("", { dir: BaseDirectory.AppData, recursive: true });
      console.log("Checking for station-cert.pem", await appDataDir());
      if (!(await exists("station-cert.pem", { dir: BaseDirectory.AppData }))) {
        setShowSecret(true);
      } else {
        setShowSecret(false);
      }
    };
    initializeDir();
  }, [isTauriApp]);

  const handleGenerate = async () => {
    if (secret === "") {
      toast.error("Secret cannot be empty");
      return;
    } else setIsGenerating(true);
  };

  const _initializeStation = async () => {
    if (window.__TAURI__) {
      await invoke("generate_certificate", {
        roomId: _stationData?.roomId,
        stationId: _stationData?.id,
      });

      const certificate = await fs.readBinaryFile("station-cert.pem", {
        dir: BaseDirectory.AppData,
      });

      const stationAppVersion = await getVersion();

      const response = await initializeStation({
        secret: secret,
        certificate: Buffer.from(certificate).toString("base64"),
        stationAppVersion: stationAppVersion as string,
      });

      if (response.data) {
        console.log("Initialized station", response.data);
        setShowSecret(false);
      } else {
        throw new Error("Failed to initialize station");
      }
    }
  };

  useEffect(() => {
    if (isGenerating) {
      setIsGenerating(false);
      if (!_stationData) {
        toast.error("Failed to get station data");
        return;
      }
      _initializeStation();
    }
  }, [isGenerating, _stationData]);

  if (showSecret) {
    return (
      <div
        style={{
          height: "100vh", // Full viewport height
          display: "flex",
          flexDirection: "column",
          overflow: "hidden", // Prevent scrolling
        }}
      >
        <Backdrop
          className="backdrop"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, display: "flex" }}
          open={true}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TextField
              fullWidth
              label="Secret"
              name="secret"
              type="text"
              autoComplete="off"
              autoFocus
              value={secret}
              onChange={(e) => setSecret(e.target.value)}
              sx={{
                mt: 2,
                "& .MuiOutlinedInput-root": {
                  // "& fieldset": {
                  //   borderColor: "white", // This will change the border color
                  // },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white", // This will change the label color
                  "&.Mui-focused": {
                    color: "white",
                  },
                },
                "& .MuiFilledInput-input": {
                  backgroundColor: "lightgray", // change this to your desired color
                },
              }}
            />
            <Button
              variant="contained"
              onClick={handleGenerate}
              size="large"
              sx={{
                mt: 2,
                ml: 2,
              }}
            >
              Confirm
            </Button>
          </Box>
        </Backdrop>
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100vh", // Full viewport height
        display: "flex",
        position: "absolute",
        flexDirection: "column",
        overflow: "hidden", // Prevent scrolling
      }}
    >
      <Backdrop
        className="backdrop"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, display: "flex" }}
        open={showLoginForm}
        onClick={() => {
          setShowLoginForm(false);
        }}
      >
        <Box
          sx={{
            m: "auto",
            display: "grid",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{
              // Centering GIF
              marginLeft: "150px",
              marginTop: "150px",
              userSelect: "none",
            }}
            height={500}
            width={500}
            src="/Tap.gif"
            alt="Loading..."
          />
        </Box>
      </Backdrop>
    </div>
  );
}
