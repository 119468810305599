import { Box, LinearProgress, Typography } from "@mui/material";
import { useStationAuth } from "../contexts/StationAuthContext";
import { CheckNetworkStatusDocument } from "../generated/graphql";
import { useEffect } from "react";
import { cacheExchange, createClient, fetchExchange } from "urql";

const MaintenanceMode = () => {
  const { maintenanceMode, setMaintenanceMode } = useStationAuth();

  const client = createClient({
    url: `${import.meta.env.VITE_APP_API!}/station/graphql`,
    exchanges: [fetchExchange, cacheExchange],
  });
  useEffect(() => {
    // Call CheckNetwork every 5 seconds
    const CheckNetwork = async () => {
      const response = await client.executeQuery({
        query: CheckNetworkStatusDocument,
        variables: {},
        key: 0,
      });
      if (response.data) {
        setMaintenanceMode(false);
      }
    };

    const interval = setInterval(() => {
      CheckNetwork();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  if (maintenanceMode)
    return (
      <>
        <Box
          sx={{
            minHeight: "100vh",
            minWidth: "100vw",
            zIndex: 100000,
            backgroundColor: "orange",
            display: "flex",
          }}
        >
          <Box sx={{ m: "auto", display: "flex", flexDirection: "column" }}>
            <Typography variant="h2" sx={{ m: "auto" }}>
              Maintenance Mode
            </Typography>

            <LinearProgress color="secondary" />

            <Typography variant="h6" sx={{ m: "auto" }}>
              Please check your network connection or wait for the maintenance
              to be completed.
            </Typography>
          </Box>
        </Box>
      </>
    );
};

export default MaintenanceMode;
