import axios, { type AxiosInstance } from "axios";
export class Auth {
  private readonly axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: `${import.meta.env.VITE_APP_API!}`,
      headers: {
        Accept: "application/json",
      },
    });

    this.axiosInstance.interceptors.request.use(
      (config) => {
        console.debug(`LOGIN request: ${config.method} ${config.url}`);
        return config;
      },
      (error) => {
        console.error(`LOGIN request: ${error.message}`, error.toJSON());
        const errorMessage =
          error.response?.data?.message != null
            ? error.response.data.message
            : error.message ?? "Unknown error occurred";

        // Throw the error message instead of the entire error object
        return Promise.reject(new Error(errorMessage));
      }
    );

    this.axiosInstance.interceptors.response.use(
      (response) => {
        console.debug(
          `LOGIN response: ${response.status} ${response.statusText}`
        );
        return response;
      },
      (error) => {
        console.error(`LOGIN response: ${error.message}`, error.toJSON());
        const errorMessage =
          error.response?.data?.message != null
            ? error.response.data.message
            : error.message ?? "Unknown error occurred";

        // Throw the error message instead of the entire error object
        return Promise.reject(new Error(errorMessage));
      }
    );
  }

  async forgotPassword(email: string): Promise<{
    success: boolean;
  }> {
    const response = await this.axiosInstance.post<{
      success: boolean;
    }>("/station/forgot-password", { email });
    return response.data;
  }

  async setPassword(
    code: string,
    password: string
  ): Promise<{
    success: boolean;
  }> {
    const response = await this.axiosInstance.post<{
      success: boolean;
    }>("/station/set-password", { code, password });
    return response.data;
  }

  async refresh(): Promise<{ token: string; refreshToken: string }> {
    const response = await this.axiosInstance.post<{
      token: string;
      refreshToken: string;
    }>("/station/refreshtoken");
    return response.data;
  }
}
