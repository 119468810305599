// routes
import Router from "./utils/router";
// theme
import ThemeProvider from "./theme";
// components
import ThemeSettings from "./components/settings";
import MotionLazyContainer from "./components/other/animate/MotionLazyContainer";
import { Toaster } from "react-hot-toast";
// ---------------------------------------------------
import { Buffer } from "buffer";
globalThis.Buffer = Buffer;
import posthog from "posthog-js";
import * as Sentry from "@sentry/react";

const api = import.meta.env.VITE_APP_API;
const runningOnProduction =
  !api?.includes("localhost") &&
  !api?.includes("staging") &&
  !api?.includes("sandbox");

runningOnProduction &&
  posthog.init("phc_Q8R8yI5LPUBx45BNYPamF2jUt9UAkT9YDh3JiRSfGWL", {
    api_host: "https://us.i.posthog.com",
    person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
    autocapture: true, // Automatically capture clicks, form submissions, etc.
    capture_pageview: true, // Automatically capture pageviews
    capture_pageleave: true, // Track when users leave your pages
    session_recording: {
      maskAllInputs: true, // Mask all input values in recordings
      maskTextSelector: "password", // Mask specific elements
    },
    loaded: (posthog) => {
      // Callback when PostHog is loaded
      console.log("PostHog loaded");
    },
  });

Sentry.init({
  environment: import.meta.env.VITE_APP_API.includes("localhost")
    ? "development"
    : import.meta.env.VITE_APP_API.includes("staging")
    ? "staging"
    : "production",
  dsn: "https://b83e00ba2ec6ea912c64ed93908f3ee7@o1353374.ingest.us.sentry.io/4507704089509888",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

import LoginForm from "./components/LoginForm";
import MaintenanceMode from "./components/MaintenanceMode";
import { useStationAuth } from "./contexts/StationAuthContext";

export default function App() {

  const { maintenanceMode, setMaintenanceMode } = useStationAuth();

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <LoginForm />
          {maintenanceMode && <MaintenanceMode />}
          {!maintenanceMode && <Router />}
          <Toaster
            position={"top-right"}
            toastOptions={{
              style: {
                backdropFilter: "blur(4px)",
              },
            }}
          />
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
