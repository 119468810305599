import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  useGetBalanceQuery,
  useBalanceSubscription,
} from "../generated/graphql";
import { useStationAuth } from "./StationAuthContext";

// ----------------------------------------------------------------------
interface BalanceContextType {
  balance: {
    entry: string | undefined;
    winnings: string | undefined;
    aleonTokens: string | undefined;
    cashout: string | undefined;
  };
}
const BalanceContext = createContext<BalanceContextType | undefined>(undefined);

type BalanceProviderProps = {
  children: ReactNode;
};

export const BalanceProvider = ({ children }: BalanceProviderProps) => {
  const { playerId } = useStationAuth();

  const [balance, setBalance] = useState<{
    entry: string | undefined;
    winnings: string | undefined;
    aleonTokens: string | undefined;
    cashout: string | undefined;
  }>({
    entry: undefined,
    winnings: undefined,
    aleonTokens: undefined,
    cashout: undefined,
  });

  const [{ data: getBalanceQuery, error: getBalanceError }] =
    useGetBalanceQuery({
      variables: {
        playerId: playerId!,
      },
      pause: !playerId,
    });

  const getBalanceQueryData = getBalanceQuery?.getBalance;

  const [
    { data: getBalanceSubscriptionResults, error: getBalanceSubscriptionError },
  ] = useBalanceSubscription({
    variables: {
      playerId: playerId!,
    },
    pause: playerId == null,
  });

  useEffect(() => {
    if (getBalanceSubscriptionResults?.balance) {
      console.log("=========================================== >Subs");

      setBalance(getBalanceSubscriptionResults?.balance);
    } else {
      if (getBalanceQuery?.getBalance) {
        console.log("=========================================== >Query");
        setBalance(getBalanceQuery?.getBalance);
      }
    }
  }, [getBalanceSubscriptionResults, getBalanceQueryData]);

  return (
    <BalanceContext.Provider
      value={{
        balance: {
          entry: balance.entry,
          winnings: balance.winnings,
          aleonTokens: balance.aleonTokens,
          cashout: balance.cashout,
        },
      }}
    >
      {children}
    </BalanceContext.Provider>
  );
};

export const useGetBalance = () => {
  const context = useContext(BalanceContext);
  if (context === undefined) {
    throw new Error(
      "useGetBalance must be used within an BalanceContextProvider"
    );
  }
  return context;
};
// ----------------------------------------------------------------------
